import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Help from '../../cart/widgets/Help';

export function renderSpecific() {
  return (
    <span>
      <em>specific</em>
      {' '}
      - Only show products specifically eligible.
      <Help title="Specific Eligibility">
        This option will only show products that have explicit approval for the selected
        aircraft/engine. You still must verify eligibility for your aircraft/engine, as some
        products may have restrictions on eligibility based on installed equipment, previous
        modifications, STC&apos;s, etc.,
      </Help>
    </span>
  );
}

export function renderBroad() {
  return (
    <span>
      <em>broad</em>
      {' '}
      - Also show products with broad eligibility.
      <Help title="Broad Eligibility">
        This option will also show products such as standard hardware, oil filters, tools, etc. that
        are broadly eligible for many aircraft. Some may not be eligible for your aircraft.
      </Help>
    </span>
  );
}

function aircraftStatus(aircraft) {
  let status = '';
  if (aircraft.make) {
    status += aircraft.make;
  }
  if (aircraft.series && !aircraft.model) {
    status += ' ';
    status += aircraft.series;
  }
  if (aircraft.model) {
    status += ' ';
    status += aircraft.model;
  }
  if (aircraft.serial_number) {
    status += ' S/N ';
    status += aircraft.serial_number;
  }
  if (aircraft.specific) {
    status += ' (specific)';
  } else {
    status += ' (broad)';
  }
  return status;
}

function renderDefault(a) {
  if (a.default) {
    return '(default)';
  }
  return '';
}

const AircraftListRadioButtons = ({
  aircraftList,
  selectedAircraftKey,
  handleChange,
  handleDelete,
}) => (
  <div style={{ display: 'block' }}>
    {aircraftList.map((a) => (
      <div key={a.key} className="radio">
        <label htmlFor="aircraft_{a.key}">
          <input
            id={`aircraft_${a.key}`}
            checked={a.key === selectedAircraftKey}
            onChange={() => handleChange(a.key)}
            type="radio"
            name="selectedAircraftKey"
            value={a.key}
          />
          {aircraftStatus(a)}
          {' '}
          {renderDefault(a)}
          {' '}
          <Button
            id={`aircraft_delete_${a.key}`}
            className="btn btn-danger btn-xs"
            onClick={() => handleDelete(a.key)}
            type="button"
          >
            X
          </Button>
        </label>
      </div>
    ))}
    <div>
      <p>
        <strong>Eligibility Options:</strong>
      </p>
      <p>{renderSpecific()}</p>
      <p>{renderBroad()}</p>
      <br />
    </div>
  </div>
);

AircraftListRadioButtons.propTypes = {
  aircraftList: PropTypes.array.isRequired,
  selectedAircraftKey: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AircraftListRadioButtons;
