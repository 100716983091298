// To enable polyfills per babel docs
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import DOM from 'react-dom';
import AircraftFilter from './components/AircraftFilter';

DOM.render(
  <AircraftFilter inline />,
  document.getElementById('aircraft-filter'),
);
