import React from 'react';

import InfoIcon from './InfoIcon';

const Help = (props) => (
  <InfoIcon
    icon="fa-question-circle"
    {...props}
  />
);

export default Help;
