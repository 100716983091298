import PropTypes from 'prop-types';

import React from 'react';
import Button from 'react-bootstrap/lib/Button';

import $ from 'jquery';
import AircraftListRadioButtons, { renderBroad, renderSpecific } from './AircraftListRadioButtons';
import AircraftEdit from './AircraftEdit';
import Help from '../../cart/widgets/Help';

class AircraftFilter extends React.Component {
  constructor(props) {
    super(props);
    this.pathname = window.location.pathname;
    this.params = new URLSearchParams(window.location.search);
    this.isFilteringMake = this.params.get('make');
    this.isFilteringSeries = this.isFilteringMake && this.params.get('series');
    this.isFilteringModel = this.isFilteringSeries && this.params.get('model');
    this.isFilteringSerialNumber = this.isFilteringModel && this.params.get('serial_number');
    this.isFilteringEligLevel = this.isFilteringMake && this.params.get('specific');
    const initialState = {
      isDisabled: true,
      view: 'status',
      aircraftList: [],
      selectedAircraftKey: undefined,
      isSavedToSession: false,
      isSavedToProfile: false,
      isLoadingAircraftList: true,
      isSavingAircraftList: false,
    };
    this.state = initialState;

    this.getSelectedAircraft = this.getSelectedAircraft.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFindProducts = this.handleFindProducts.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleSelectedAircraftChange = this.handleSelectedAircraftChange.bind(this);
    this.doClearFilterRedirect = this.doClearFilterRedirect.bind(this);
  }

  componentDidMount() {
    $.ajax({
      url: '/products/aircraft',
      method: 'GET',
      data: {},
      contentType: 'application/json',
    }).done((data) => {
      const aircraftData = data;
      if (!aircraftData.aircraftList) {
        aircraftData.aircraftList = [];
      }
      const defaultAircraft = aircraftData.aircraft_list.filter((a) => a.default)[0];
      let { selectedAircraftKey } = this.state;
      if (defaultAircraft) {
        selectedAircraftKey = defaultAircraft.key;
      }
      this.setState({
        isLoadingAircraftList: false,
        aircraftList: aircraftData.aircraft_list,
        isSavedToSession: aircraftData.is_saved_to_session,
        isSavedToProfile: aircraftData.is_saved_to_profile,
        selectedAircraftKey,
        isDisabled: !selectedAircraftKey,
      });
    });
  }

  handleDelete(key) {
    const newAircraftList = this.state.aircraftList.filter((obj) => obj.key !== key);
    const curKey = this.state.selectedAircraftKey;
    const newSelectedAircraftKey = curKey === key ? undefined : curKey;
    this.saveAircraftList(newAircraftList, newSelectedAircraftKey);
  }

  handleSelectedAircraftChange(key) {
    this.setState({ isDisabled: !key, selectedAircraftKey: key });
  }

  handleFindProducts(aircraft) {
    this.applyAircraft(aircraft, '/products/');
  }

  handleSave(aircraft, view) {
    if (aircraft) {
      let newAircraftList = [];
      const ac = { ...aircraft };
      if (ac.key) {
        // update existing
        const al = [...this.state.aircraftList];
        newAircraftList = al.map((obj) => (obj.key !== aircraft.key ? obj : aircraft));
      } else {
        // create new
        if (this.state.aircraftList.length > 0) {
          ac.key = Math.max.apply(
            null,
            this.state.aircraftList.map((a) => a.key),
          ) + 1;
        } else {
          ac.key = 1;
        }
        newAircraftList = [...this.state.aircraftList, ac];
      }
      if (ac.default) {
        /* eslint-disable no-param-reassign */
        newAircraftList.forEach((a) => { a.default = a.key === ac.key; });
        /* eslint-enable no-param-reassign */
      }
      this.saveAircraftList(newAircraftList, ac.key, view);
    } else {
      this.setState({ view: view || 'change' });
    }
  }

  getChangeAddButtonLabel() {
    if (this.state.aircraftList.length > 0) {
      return this.isFilteringMake ? 'Change Aircraft' : 'Select Aircraft';
    }
    return this.isFilteringMake ? 'Change Aircraft' : 'Add Aircraft';
  }

  getAircraftFromQueryString() {
    const make = this.params.get('make') || '';
    const series = this.params.get('series') || '';
    const model = this.params.get('model') || '';
    const serialNumber = this.params.get('serial_number') || '';
    return {
      key: 0,
      make,
      series,
      model,
      serial_number: serialNumber,
      default: false,
    };
  }

  getSelectedAircraft() {
    return this.getAircraft(this.state.selectedAircraftKey);
  }

  getAircraft(key) {
    if (key) {
      return this.state.aircraftList.filter((a) => a.key === key)[0];
    }
    return undefined;
  }

  currentFilterSaved() {
    const ac = this.getAircraftFromQueryString();
    if (!ac.make) {
      return true;
    }
    const aircraft = this.state.aircraftList.filter(
      (a) => ac.make === a.make
        && ac.series === a.series
        && ac.model === a.model
        && ac.serial_number === a.serial_number,
    )[0];
    return Boolean(aircraft);
  }

  applyAircraft(aircraft, url) {
    this.params.delete('make');
    this.params.delete('series');
    this.params.delete('model');
    this.params.delete('serial_number');
    this.params.delete('specific');
    this.params.delete('no_default_aircraft');
    if (aircraft.make) {
      this.params.set('make', aircraft.make);
      if (aircraft.specific) {
        this.params.set('specific', '1');
      }
      if (aircraft.series) {
        this.params.set('series', aircraft.series);
        if (aircraft.model) {
          this.params.set('model', aircraft.model);
          if (aircraft.serial_number) {
            this.params.set('serial_number', aircraft.serial_number);
          }
        }
      }
    }
    const qs = this.params.toString();
    let href = url;
    if (qs) {
      href = `${href}?${qs}`;
    }
    window.location.href = href;
  }

  applySelectedAircraft() {
    const aircraft = this.getSelectedAircraft();
    this.applyAircraft(aircraft, this.pathname);
  }

  doClearFilterRedirect() {
    // redirect to the same page without any eligibility filters applied. also clear the default
    // aircraft saved to the user's profile.
    const al = [...this.state.aircraftList];
    /* eslint-disable no-param-reassign */
    al.forEach((a) => { a.default = false; });
    /* eslint-enable no-param-reassign */
    this.saveAircraftList(al);
    this.params.delete('make');
    this.params.delete('series');
    this.params.delete('model');
    this.params.delete('serial_number');
    this.params.delete('specific');
    const qs = this.params.toString();
    let href = this.pathname;
    if (qs) {
      href = `?${qs}`;
    }
    window.location.href = href;
  }

  saveAircraftList(aircraftList, selectedAircraftKey, view) {
    this.setState({ isSavingAircraftList: true });
    $.ajax({
      url: '/products/aircraft',
      method: 'POST',
      data: JSON.stringify({ aircraft_list: aircraftList }),
      contentType: 'application/json',
    }).done((data) => {
      if ('aircraft_list' in data) {
        this.setState({
          isSavingAircraftList: false,
          isDisabled: !selectedAircraftKey,
          selectedAircraftKey,
          isSavedToSession: data.is_saved_to_session,
          isSavedToProfile: data.is_saved_to_profile,
          aircraftList: data.aircraft_list,
          view: view || 'change',
        }, () => {
          if (this.state.selectedAircraftKey && aircraftList.length === 1) {
            this.applySelectedAircraft();
          }
        });
      }
      // TODO: else show error to user
    });
  }

  renderFilterStatus() {
    if (!this.isFilteringMake) {
      return (
        <div>
          <div>
            <strong>Your Aircraft/Engine</strong>
            <Help placement="right">
              Click
              {' '}
              {this.getChangeAddButtonLabel()}
              {' '}
              to select your aircraft/engine. Then when you
              browse the categories or search, only products that may be eligible for the
              aircraft/engine selected will be displayed. You can be as specific as you like. For
              example: selecting just a Make will limit products to those eligible for that Make, or
              selecting Make, Series, Model, and Serial Number will limit products to those that may
              be eligible for one specific serial number.
            </Help>
          </div>
          <div>None selected.</div>
        </div>
      );
    }
    let specificMsg = '';
    if (this.params.get('specific')) {
      specificMsg = (
        <span>
          <b>Option: </b>
          {renderSpecific()}
        </span>
      );
    } else {
      specificMsg = (
        <span>
          <b>Option: </b>
          {renderBroad()}
        </span>
      );
    }

    return (
      <div>
        <div>
          <strong>Your Aircraft or Engine</strong>
          <Help placement="right">
            Only products that may be eligible for the aircraft/engine selected will be displayed.
          </Help>
        </div>
        <div>
          {this.isFilteringMake ? (
            <span>
              <b>Make:</b>
              {' '}
              {this.params.get('make')}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>
          {this.isFilteringSeries ? (
            <span>
              <b>Series:</b>
              {' '}
              {this.params.get('series')}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>
          {this.isFilteringModel ? (
            <span>
              <b>Model:</b>
              {' '}
              {this.params.get('model')}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>
          {this.isFilteringSerialNumber ? (
            <span>
              <b>Serial Number:</b>
              {' '}
              {this.params.get('serial_number')}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>{specificMsg}</div>
      </div>
    );
  }

  renderCurrentFilterSavedStatus() {
    const defaultAircraft = this.state.aircraftList.filter((a) => a.default)[0];
    let matchDefault = '';
    if (defaultAircraft) {
      if (defaultAircraft.make === (this.params.get('make') || '')) {
        if (defaultAircraft.series === (this.params.get('series') || '')) {
          if (defaultAircraft.model === (this.params.get('model') || '')) {
            if (defaultAircraft.serial_number === (this.params.get('serial_number') || '')) {
              matchDefault = (
                <div style={{ paddingBottom: '1rem' }}>
                  <strong>
                    The currently selected aircraft/engine is your default. Click Change to either
                    remove or change your default.
                  </strong>
                </div>
              );
            }
          }
        }
      }
    }
    let saved = '';
    if (!this.currentFilterSaved()) {
      saved = (
        <div style={{ paddingBottom: '1rem' }}>
          <strong>
            The currently selected aircraft/engine is not saved; to save it for future searches,
            click save below.
          </strong>
        </div>
      );
    }
    return (
      <div>
        {saved}
        {matchDefault}
      </div>
    );
  }

  renderSavedStatus() {
    if (this.state.isSavedToProfile) {
      return <div>Your aircraft and engines are saved to your user profile.</div>;
    }
    if (this.state.isSavedToSession) {
      return (
        <div>
          Your aircraft and engines are saved to your browser session. Sign in to keep your saved
          aircraft and engines between sessions.
        </div>
      );
    }
    return <div />;
  }

  renderSaveButton() {
    if (!this.currentFilterSaved()) {
      const aircraft = this.getAircraftFromQueryString();
      return (
        <Button
          className="btn btn-aircraft-filter"
          onClick={() => this.handleSave(aircraft, 'status')}
          type="button"
        >
          Save
        </Button>
      );
    }
    return '';
  }

  renderChangeAddButton() {
    let newView = 'edit';
    if (this.state.aircraftList.length > 0) {
      newView = 'change';
    }
    return (
      <Button
        key="change"
        className="btn btn-success"
        onClick={() => this.setState({ view: newView })}
        type="button"
      >
        {this.getChangeAddButtonLabel()}
      </Button>
    );
  }

  renderStatus() {
    return (
      <div>
        {this.renderFilterStatus()}
        <br />
        {this.renderCurrentFilterSavedStatus()}
        <div className={this.props.buttonAlign}>
          {this.renderChangeAddButton()}
          {this.renderSaveButton()}
          <Button
            key="statusClear"
            className="btn btn-default"
            onClick={this.doClearFilterRedirect}
            type="button"
          >
            Clear
          </Button>
        </div>
      </div>
    );
  }

  renderEdit() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div>
              <div>
                <strong>Your Aircraft or Engine</strong>
                <Help placement="right">
                  Select your aircraft/engine and click submit. Then when you browse the categories
                  or search, only products that may be eligible for the aircraft or engine selected
                  will be displayed. You can be as specific as you like. For example: selecting just
                  a Make will limit products to those eligible for that Make, or selecting Make,
                  Series, Model, and Serial Number will limit products to those that may be eligible
                  for one specific serial number.
                </Help>
              </div>
            </div>
            <AircraftEdit
              aircraft={this.getSelectedAircraft()}
              handleSave={this.handleSave}
              buttonAlign={this.props.buttonAlign}
              handleFindProducts={this.handleFindProducts}
            />
          </div>
        </div>
      </div>
    );
  }

  renderChange() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12">
            <div>
              <div>
                <strong>Your Saved Aircraft/Engines</strong>
                <Help placement="right" title="Your Saved Aircraft/Engines">
                  <p>
                    Select an aircraft/engine, then when you browse product categories or search,
                    only products that may be eligible for the aircraft/engine selected will be
                    displayed.
                  </p>
                  <br />
                  <p>
                    You can be as specific as you like. For example: selecting just a Make will
                    limit products to those eligible for that Make, or selecting Make, Series,
                    Model, and Serial Number will limit products to those that may be eligible for
                    one specific serial number.
                  </p>
                  <br />
                  <p>
                    {/*
                    Click
                    {' '}
                    <Button
                      className="btn btn-aircraft-filter btn-xs"
                    >
                      Default
                    </Button>
                    {' '}
                     to toggle an aircraft/engine as your default (blue=default,
                    white=not default).
                    */}
                    Click
                    {' '}
                    <Button className="btn btn-danger btn-xs">X</Button>
                    {' '}
                    to delete an aircraft
                    or engine option.
                  </p>
                </Help>
              </div>
            </div>
          </div>
        </div>
        <AircraftListRadioButtons
          aircraftList={this.state.aircraftList}
          selectedAircraftKey={this.state.selectedAircraftKey}
          handleChange={this.handleSelectedAircraftChange}
          handleDelete={this.handleDelete}
        />
        {this.renderSavedStatus()}
        <div className={this.props.buttonAlign}>
          <Button
            className="btn btn-success"
            disabled={this.state.isDisabled}
            onClick={() => this.applySelectedAircraft()}
          >
            Search
          </Button>
          <Button
            className="btn btn-aircraft-filter"
            disabled={!this.state.selectedAircraftKey}
            onClick={() => this.setState({ view: 'edit' })}
          >
            Edit
          </Button>
          <Button
            className="btn btn-aircraft-filter"
            onClick={() => this.setState({ selectedAircraftKey: undefined, view: 'edit' })}
          >
            New
          </Button>
          <Button className="btn btn-default" onClick={() => this.setState({ view: 'status' })}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isLoadingAircraftList) {
      return (
        <div>
          <div>
            <strong>Aircraft Search</strong>
          </div>
          <div>Loading...</div>
        </div>
      );
    }
    if (this.state.isSavingAircraftList) {
      return (
        <div>
          <div>
            <strong>Aircraft Search</strong>
          </div>
          <div>Saving...</div>
        </div>
      );
    }
    if (this.props.inline) {
      // inline is used for home page
      return (
        <AircraftEdit
          aircraft={this.getSelectedAircraft()}
          handleSave={this.handleSave}
          buttonAlign={this.props.buttonAlign}
          inline={this.props.inline}
          handleFindProducts={this.handleFindProducts}
        />
      );
    }
    if (this.state.view === 'edit') {
      return this.renderEdit();
    }
    if (this.state.view === 'change') {
      return this.renderChange();
    }
    return this.renderStatus();
  }
}

AircraftFilter.defaultProps = {
  buttonAlign: 'text-right',
  inline: false,
};

AircraftFilter.propTypes = {
  buttonAlign: PropTypes.string,
  inline: PropTypes.bool,
};

export default AircraftFilter;
