import PropTypes from 'prop-types';
import React from 'react';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';

class InfoIcon extends React.Component {
  constructor(props) {
    super(props);
    this.hidePopover = this.hidePopover.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.getLinkClass = this.getLinkClass.bind(this);
  }

  getIcon() {
    const iconClass = `${this.props.size} fa ${this.props.icon}`;
    const linkClass = this.getLinkClass();
    const icon = (
      <a role="button" tabIndex="0" className={linkClass}>
        <i className={iconClass} />
      </a>
    );
    return icon;
  }

  getLinkClass() {
    let linkClass = 'help-link';
    if (this.props.linkClass) linkClass = `${this.props.linkClass} ${linkClass}`;
    return linkClass;
  }

  hidePopover() {
    this.notice.hide();
  }

  renderPopover() {
    const className = 'fa fa-close';
    const title = (
      <span>
        {this.props.title}
        <a className="close-popover" onClick={this.hidePopover} href="#">
          <i className={className} />
        </a>
      </span>
    );
    const popover = (
      <Popover
        placement={this.props.placement}
        className="info-popover"
        id={this.props.icon}
        container={this.props.container}
        title={title}
      >
        {this.props.children}
      </Popover>
    );
    return popover;
  }

  render() {
    const icon = this.getIcon();
    if (!this.props.children) return icon;

    return (
      <OverlayTrigger
        trigger={this.props.trigger}
        placement={this.props.placement}
        overlay={this.renderPopover()}
        ref={(c) => { this.notice = c; }}
        defaultOverlayShown={this.props.defaultOverlayShown}
      >
        {icon}
      </OverlayTrigger>
    );
  }
}

InfoIcon.defaultProps = {
  container: 'false',
  defaultOverlayShown: false,
  trigger: ['click'],
  placement: 'top',
  title: '',
  size: 'fa-lg',
};

InfoIcon.propTypes = {
  container: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  trigger: PropTypes.any,
  linkClass: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  defaultOverlayShown: PropTypes.bool,
};
export default InfoIcon;
