import PropTypes from 'prop-types';
import React from 'react';
import FieldGroup from './FieldGroup';

class AircraftSelectInput extends React.Component {
  getValue() {
    return this.refs.inputRef.getValue();
  }

  static renderItem(item, k) {
    return (
      <option key={k} value={item}>
        {item}
      </option>
    );
  }

  render() {
    if (!this.props.shouldShow) {
      return <FieldGroup id={this.props.name} type="hidden" ref="inputRef" value="" />;
    }
    const items = this.props.optionList.map((i, k) => AircraftSelectInput.renderItem(i, k));
    const label = this.props.showLabel ? this.props.label : '';
    return (
      <FieldGroup
        onChange={this.props.handleChange}
        key={this.props.name}
        ref="inputRef"
        componentClass="select"
        value={this.props.value}
        id={this.props.name}
        name={this.props.name}
        label={label}
      >
        <option key="--" value="">
          Select
          {' '}
          {this.props.label}
        </option>
        {items}
      </FieldGroup>
    );
  }
}

AircraftSelectInput.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  optionList: PropTypes.array,
  shouldShow: PropTypes.bool,
  showLabel: PropTypes.bool,
};

AircraftSelectInput.defaultProps = {
  optionList: [],
  shouldShow: true,
  showLabel: true,
};

export default AircraftSelectInput;
