import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import Button from 'react-bootstrap/lib/Button';
import FieldGroup from './FieldGroup';
import Help from '../../cart/widgets/Help';
import { renderBroad, renderSpecific } from './AircraftListRadioButtons';

import AircraftSelectInput from './AircraftSelectInput';

class AircraftEdit extends React.Component {
  constructor(props) {
    super(props);
    this.blankAircraft = {
      key: 0,
      make: '',
      series: '',
      model: '',
      serial_number: '',
      specific: false,
      default: true,
    };
    let aircraft = this.blankAircraft;
    if (this.props.aircraft) {
      aircraft = { ...this.props.aircraft };
    }
    const initialState = {
      aircraft,
      makeList: [],
      seriesList: [],
      modelList: [],
      isLoadingLists: true,
    };
    this.state = initialState;
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    this.refreshAircraftList(this.props.aircraft);
  }

  handleClear(event) {
    event.preventDefault();
    this.setState({ aircraft: this.blankAircraft });
  }

  handleSelectChange(event) {
    const { name, value } = event.target;
    const aircraft = { ...this.state.aircraft };
    aircraft[name] = value;
    // clear others
    if (name === 'make') {
      aircraft.series = '';
      aircraft.model = '';
      aircraft.serial_number = '';
    }
    if (name === 'series') {
      aircraft.model = '';
      aircraft.serial_number = '';
    }
    if (name === 'model') {
      aircraft.serial_number = '';
    }
    if (name === 'default') {
      aircraft[name] = event.target.checked;
    }
    if (name === 'specific') {
      if (event.target.checked) {
        aircraft[name] = '1';
      } else {
        delete aircraft[name];
      }
    }
    if (name === 'broad') {
      if (event.target.checked) {
        delete aircraft.specific;
      } else {
        aircraft.specific = '1';
      }
    }
    if (['model', 'serial_number', 'specific', 'broad', 'default'].indexOf(name) >= 0) {
      this.setState({ aircraft });
    } else {
      this.setState({ aircraft }, () => this.refreshAircraftList(this.state.aircraft));
    }
  }

  shouldShowSerial() {
    return this.state.aircraft.make && this.state.aircraft.series && this.state.aircraft.model;
  }

  refreshAircraftList(paramData) {
    this.setState({ isLoadingLists: true });
    $.ajax({
      url: '/products/aircraft',
      method: 'GET',
      data: paramData,
      contentType: 'application/json',
    }).done((data) => {
      const aircraftData = data;
      this.setState({
        isLoadingLists: false,
        makeList: aircraftData.makeList,
        seriesList: aircraftData.seriesList,
        modelList: aircraftData.modelList,
      });
    });
  }

  renderSerialInput(showLabel) {
    if (!this.shouldShowSerial()) {
      return <FieldGroup id="serial_number" type="hidden" value="" />;
    }
    return (
      <FieldGroup
        id="serial_number"
        name="serial_number"
        onChange={this.handleSelectChange}
        type="text"
        value={this.state.aircraft.serial_number}
        label={showLabel ? 'Serial Number' : ''}
        placeholder="S/N (optional)"
      />
    );
  }

  renderInline() {
    const makeClassName = this.state.aircraft.make ? 'd-none d-md-block' : '';
    const seriesClassName = this.state.aircraft.series ? 'd-none d-md-block' : '';
    const modelClassName = this.state.aircraft.model ? 'd-none d-md-block' : '';
    if (this.state.isLoadingLists) {
      return (
        <div>
          <h2
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              marginTop: '0.5rem',
              lineHeight: '1.0',
            }}
          >
            Aircraft Search
          </h2>
          <div>Loading...</div>
        </div>
      );
    }
    return (
      <div>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row">
            <h2
              style={{
                fontSize: '1rem',
                fontWeight: 'bold',
                marginTop: '0.5rem',
                lineHeight: '1.0',
              }}
            >
              Aircraft Search
            </h2>
            &nbsp;
            <div
              style={{
                fontSize: '0.8rem',
                marginBottom: '0.5rem',
                marginTop: '0.5rem',
                lineHeight: '1.0',
              }}
            >
              {this.state.aircraft.make ? `Make: ${this.state.aircraft.make}` : ''}
              {this.state.aircraft.make && this.state.aircraft.series
                ? `, Series: ${this.state.aircraft.series}`
                : ''}
              {this.state.make && this.stat.aircrafte.series && this.state.aircraft.model
                ? `, Model: ${this.state.aircraft.model} `
                : ''}
              {this.state.aircraft.make ? ' ' : ''}
              &nbsp; &nbsp;
              <span style={{ fontSize: '1rem' }}>
                {this.state.aircraft.make ? (
                  <strong>
                    <a href="/" onClick={this.handleClear}>
                      Clear
                    </a>
                  </strong>
                ) : (
                  ''
                )}
              </span>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className={makeClassName}>
              <AircraftSelectInput
                name="make"
                label="Make"
                showLabel={false}
                optionList={this.state.makeList}
                handleChange={this.handleSelectChange}
                value={this.state.aircraft.make}
              />
            </div>
            &nbsp;
            <div className={seriesClassName}>
              <AircraftSelectInput
                name="series"
                label="Series"
                showLabel={false}
                shouldShow={Boolean(this.state.aircraft.make)}
                optionList={this.state.seriesList}
                handleChange={this.handleSelectChange}
                value={this.state.aircraft.series}
              />
            </div>
            &nbsp;
            <div className={modelClassName}>
              <AircraftSelectInput
                name="model"
                label="Model"
                showLabel={false}
                shouldShow={Boolean(this.state.aircraft.make && this.state.aircraft.series)}
                optionList={this.state.modelList}
                handleChange={this.handleSelectChange}
                value={this.state.aircraft.model}
              />
            </div>
            &nbsp;
            {this.renderSerialInput(false)}
            &nbsp;
            <div style={{ marginLeft: 'auto' }}>
              <Button
                className="btn btn-success"
                style={{ minWidth: '103px' }}
                disabled={!this.state.aircraft.make}
                onClick={() => this.props.handleFindProducts(this.state.aircraft)}
              >
                Find Products
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDefaultCheckbox() {
    if (!this.state.aircraft.make) {
      return <div />;
    }
    return (
      <div>
        <label style={{ display: 'inline' }} htmlFor="default_1">
          <input
            id="default_1"
            checked={Boolean(this.state.aircraft.default)}
            onChange={this.handleSelectChange}
            type="checkbox"
            name="default"
          />
          {' '}
          Make this your default aircraft/engine.
        </label>
        <Help title="Default">
          The aircraft/engine that is the default will automatically be applied anytime you search
          or browse the products page. You do not have to have a default, in which case, no
          aircraft/engine will automatically be applied.
        </Help>
      </div>
    );
  }

  renderSpecificRadioButtons() {
    return (
      <div style={{ display: 'block' }}>
        <div className="radio">
          <label htmlFor="specific">
            <input
              id="specific"
              checked={Boolean(this.state.aircraft.specific)}
              onChange={this.handleSelectChange}
              type="radio"
              name="specific"
              value="specific"
            />
            {renderSpecific()}
          </label>
        </div>
        <div className="radio">
          <label htmlFor="broad">
            <input
              id="broad"
              checked={!this.state.aircraft.specific}
              onChange={this.handleSelectChange}
              type="radio"
              name="broad"
              value="broad"
            />
            {renderBroad()}
          </label>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.inline) {
      return this.renderInline();
    }
    if (this.state.isLoadingLists) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <AircraftSelectInput
          name="make"
          label="Make"
          optionList={this.state.makeList}
          handleChange={this.handleSelectChange}
          value={this.state.aircraft.make}
        />
        <AircraftSelectInput
          name="series"
          label="Series"
          shouldShow={Boolean(this.state.aircraft.make)}
          optionList={this.state.seriesList}
          handleChange={this.handleSelectChange}
          value={this.state.aircraft.series}
        />
        <AircraftSelectInput
          name="model"
          label="Model"
          shouldShow={Boolean(this.state.aircraft.make && this.state.aircraft.series)}
          optionList={this.state.modelList}
          handleChange={this.handleSelectChange}
          value={this.state.aircraft.model}
        />
        {this.renderSerialInput(true)}
        {this.renderSpecificRadioButtons()}
        {this.renderDefaultCheckbox()}
        <div className={this.props.buttonAlign}>
          <Button
            className="btn btn-success"
            disabled={!this.state.aircraft.make}
            onClick={() => this.props.handleSave(this.state.aircraft)}
          >
            Save
          </Button>
          <Button className="btn btn-default" onClick={() => this.props.handleSave()}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

AircraftEdit.propTypes = {
  aircraft: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  handleFindProducts: PropTypes.func.isRequired,
  buttonAlign: PropTypes.string,
  inline: PropTypes.bool,
};

export default AircraftEdit;
